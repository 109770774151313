import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as CloudFront from 'aws-sdk/clients/cloudfront';
import {UtilsService} from "./utils.service";
import * as moment from 'moment';
import {SessionStorageService} from "./session.storage.service";
@Injectable({
  providedIn: 'root'
})
export class AwsService {
  constructor(private utilsService:UtilsService,
              private sessionStorageService:SessionStorageService) {
  }
  fileUpload(file,cb) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );
    const logged = this.sessionStorageService.getLoggedUser()
    const fileNameArr = file.name.split('.')
    const ext = fileNameArr[fileNameArr.length-1]
    const filename = this.utilsService.getRandomString(10)+"."+ext
    console.log(logged)
    const params = {
      Bucket: 'callcenterupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }

  async fileUploadCrm(file) {
    return new Promise((resolve, reject) => {
      const contentType = file.type;
      const bucket = new S3(
        {
          accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
          secretAccessKey: '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',
          region: 'ca-central-1',
        }
      );
      const logged = this.sessionStorageService.getLoggedUser()
      const fileNameArr = file.name.split('.')
      const ext = fileNameArr[fileNameArr.length-1]
      const filename = this.utilsService.getRandomString(10)+"."+ext
      // console.log(logged)
      const params = {
        Bucket: 'crmupload',
        Key: logged.organization.id+'/'+filename,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          console.log('EROOR: ',JSON.stringify( err));
          reject(err);
        }
        console.log('File Uploaded.', data);
        resolve(data)
      });
    })
    
  }

  getFile(key: string, cb){
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );

    const params = {
      Bucket: 'callcenterupload',
      Key: key
    };

    bucket.getObject(params, cb)
  }


  uploadTranslateFile(language,data,cb) {
    const s3 = new S3({
        accessKeyId: 'AKIAUYAI2DKBH2GC6GEG',
        secretAccessKey: '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',
        region: 'ca-central-1',
    });

    const cloudfront = new CloudFront({
      accessKeyId: 'AKIAUYAI2DKBH2GC6GEG',
      secretAccessKey: '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',
        region: 'ca-central-1',
    });


  const file_name = `assets/i18n/${language}.json`

  s3.putObject({
      Bucket: 'crmfrontend',
      Key: file_name,
      Body: JSON.stringify(data),
      ACL: 'bucket-owner-full-control',
      ContentType: 'application/json'
  }, function(err, data) {
    if (err) {
      console.log(err)
      cb('error',err)
    }else{
        const param= {
          DistributionId:'E7DESPHKGR9CP',
          InvalidationBatch:{
              CallerReference:moment().unix()+'',
              Paths:{
                  Quantity:1,
                  Items:[
                      `/${file_name}`
                  ]
              }
          }
      }
      cloudfront.createInvalidation(param, function(err, data) {
          if (err){
            console.log(err)
            cb('error',err)
          }else{
            cb('success')
          }
        });
    }
    
  });


  }


  fileUploadCrop(file,cb) {

    const contentType = "image/png";

    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
        secretAccessKey: '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',
        region: 'ca-central-1'
      }
    );

    const logged = this.sessionStorageService.getLoggedUser()

    const ext = "png"
    const filename = this.utilsService.getRandomString(10)+"."+ext

    const params = {
      Bucket: 'callcenterupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }

  fileUploadForDial(file,cb,key_file="") {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAUYAI2DKBNHNPFIEE',
        secretAccessKey: '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',
        region: 'ca-central-1',
      }
    );
    const logged = this.sessionStorageService.getLoggedUser()
    const fileNameArr = file.name.split('.')
    const ext = fileNameArr[fileNameArr.length-1]
    const filename = this.utilsService.getRandomString(10)+"."+ext
    
    if(key_file===""){
      key_file=  logged.organization.id+'/'+filename
    }else{
      key_file = `${key_file}/${filename}`
    }

    const params = {
      Bucket: 'callcenterupload',
      Key: key_file,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
}

