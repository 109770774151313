import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {retry, catchError, finalize} from 'rxjs/operators';
import {AuthService} from "./services/auth.service";
import {SessionStorageService} from "./services/session.storage.service";
import {UtilsService} from "./services/utils.service";

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService:AuthService,
    private utils:UtilsService,
    private sessionStorageService:SessionStorageService,
    private router:Router
  ) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request.url.includes("last-seen-check") && !request.url.includes("last-seen")  && !request.url.includes("hooper") && !request.url.includes("progression")&& !request.url.includes("call_list/detail")){
      this.utils.showLoading()
    }
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          
          let is_show_error=1

          // console.log(error)

          console.log(error)
          if(error.name==="HttpErrorResponse" && error.status===401){
            // console.log('11111')
            try{

              this.authService.logout();
              this.sessionStorageService.clear()
              this.router.navigateByUrl("/")
            }catch(e){
              this.router.navigateByUrl("/")
            }
            is_show_error=0
          }
          // if(error.error.status==="ERROR" && error.error.message==="Token expired"){
          //   this.authService.logout();
          //   this.sessionStorageService.clear()
          //   is_show_error=0
          // }

          if(is_show_error===1){
            this.utils.showNotify("error","Error , Please try again")
          }


          return throwError(errorMessage);
        }),
        finalize(()=>{
          this.utils.hideLoading()
        })
      )
  }
}
