import { Injectable } from '@angular/core';
import {SessionStorageService} from "./session.storage.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {CallCenterToast} from "../../shared/call-center.toast";
import {ToastrService} from "ngx-toastr";
import { ApiFileService } from './api/api-file.service';
import { isValidNumber, parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import validator from 'validator';

import * as CryptoJS from 'crypto-js';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { constants } from 'perf_hooks';

import * as AWS from 'aws-sdk/global';

import { ApiCrmFileService } from './crm_api/api-file.service';

import {
	CognitoUserPool
} from 'amazon-cognito-identity-js';
import { async } from 'rxjs';

import * as moment from 'moment-timezone';
import MsgReader from '@kenjiuno/msgreader'
import {decompressRTF}  from '@kenjiuno/decompressrtf';
import rtfToHTML from '@iarna/rtf-to-html';
import mime from'mime';

const POOL_DATA = {
	UserPoolId:environment.AwsUserPoolId, // Your user pool id here
	ClientId: environment.AwsClientId, // Your client id here
};
const userPool = new CognitoUserPool(POOL_DATA);

declare var postalMime: any;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private sessionStorageService:SessionStorageService,
    private router:Router,
    private translate:TranslateService,
    private toastr: ToastrService,
    private ApiCrmFileService: ApiCrmFileService,
    private httpClient: HttpClient
  ) {  }
  getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math. random() * randomChars.length));
    }
    return result;
  }
  adminRefresh(url){
    this.router.navigateByUrl('/admin/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });
  }
  getMenuTitle(){
    return {
      users:{
        title:this.translate.instant("Agents"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      list:{
        title:this.translate.instant("List"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      campaign:{
        title:this.translate.instant("Campaign"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      'audio-files':{
        title:this.translate.instant("Audio files"),
        sub_title:this.translate.instant("Centre d'appel")
      },
      dnc:{
        title:this.translate.instant("DNC"),
        sub_title:this.translate.instant("Centre d'appel")
      }
    }
  }


  async refreshToken(force=0){
    const cognitoUser = userPool.getCurrentUser()
    // console.log(cognitoUser)
    if(cognitoUser){

      const logged = this.sessionStorageService.getLoggedUser()
      cognitoUser.getSession((err, session) => {
        if(err){
          console.log('session error 1',err)
          cognitoUser.signOut()
          this.router.navigateByUrl('/')
          
        }else{
          const access_token= session.getAccessToken().getJwtToken()
          if(logged.token !== access_token || session.isValid() === false){
            
            const refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
              
            AWS.config.region = environment.AwsRegion;
            const user_pool_key = 'cognito-idp.'+environment.AwsRegion+'.amazonaws.com/'+environment.AwsUserPoolId
            const login = {}
            login[user_pool_key]=session.getIdToken().getJwtToken();

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
              Logins: login,
            });

            if((AWS.config.credentials as AWS.CognitoIdentityCredentials).needsRefresh()){

              cognitoUser.refreshSession(refresh_token, (err, session_new) => {
                if (err) {
                  // cognitoUser.signOut()
                  console.log('session error 2',err)
                  cognitoUser.signOut()
                  this.router.navigateByUrl('/')
                } else {
                  (AWS.config.credentials as AWS.CognitoIdentityCredentials).params['Logins'][user_pool_key] = session_new.getIdToken().getJwtToken();
                  (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(err => {
                    if (err) {
                      cognitoUser.signOut()
                    } else {
                      // Token
                      // console.log('token refreshed')
                      const logged = this.sessionStorageService.getLoggedUser();
                      logged.token = session_new.getAccessToken().getJwtToken()
                      this.sessionStorageService.set("logged",logged)

                      this.httpClient.post<any>(`${environment.api_url}/api/user/update-token`,{
                        email:session_new.getIdToken().payload.email,
                        refresh_token:session_new.getRefreshToken().getToken(),
                        access_token:session_new.getAccessToken().getJwtToken(),
                      },this.getAuthEmailHeader(session_new.getIdToken().payload.email)).subscribe(res=>{
                        if(res.status==="ERROR"){
                          console.log(res.message)
                        }
                      });
                    }
                  });
                }
              });
            }
          }else{

            // console.log('session error 3')
            // cognitoUser.signOut()
          }
        }
      })
    }else{
      this.router.navigateByUrl('/')
    }

  }

  scrollToBottom(element){
    element.scrollTop = element.scrollHeight
  }

  getAuthHeader(){
    const logged = this.sessionStorageService.get('logged')
    let tok= ""
    if(logged!==undefined &&  logged.token !== undefined){
      tok  = logged.token
    }
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': tok
      })
    }
  }
  

  getAuthEmailHeader(email){
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': CryptoJS.AES.encrypt(email, environment.secret_key).toString()
      })
    }
  }


  humanFileSize(size) {
    const i = Math.floor( Math.log(size) / Math.log(1024) );
    return parseInt((Math.ceil(size / Math.pow(1024, i)) ).toFixed(2)) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  }
  
  encodeString(string){
    return CryptoJS.AES.encrypt(string, environment.secret_key).toString()
  }

  decodeString(string){
    const bytes  = CryptoJS.AES.decrypt(string,environment.secret_key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  getEmailEncode(email){
    return CryptoJS.AES.encrypt(email, environment.secret_key).toString()
  }
  getAuthDemocratikHeader(){
    const logged = this.sessionStorageService.get('logged')
    const democratik_config = logged.integrations.find(x=>x.partner==="democratik")
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'AUTH': `Basic ${democratik_config.partner_config.api_key}`
      })
    }
  }
  // showNotify(type,message,title="",ops_config={}){
  //   let ops={}
  //   if(Object.keys(ops_config).length === 0){
  //     ops={
  //       // toastComponent:CallCenterToast,
  //       titleClass:'toast-title '+type,
  //       closeButton:true,
  //       timeOut:3000
  //       // easeTime:3000
  //     }
  //   }else{
  //     ops = {
  //       // toastComponent:CallCenterToast,
  //       titleClass:'toast-title '+type,
  //       ...ops_config
  //     }
  //   }
  //   // console.log(ops)
  //   if(type=="success"){
  //     if(title===""){
  //       title = "Success"
  //     }
  //     this.toastr.success(message,title,ops)
  //   }else if(type=="error"){
  //     if(title===""){
  //       title = "Error"
  //     }
  //     this.toastr.error(message,title,ops)
  //   }else if(type=="plivo"){
  //     if(title===""){
  //       title = "Alert Notification"
  //     }
  //     this.toastr.error(message,title,ops)
  //   }else if(type=="warning"){
  //     if(title===""){
  //       title = "Warning"
  //     }
  //     this.toastr.warning(message,title,ops)
  //   }else if(type=="info"){
  //     if(title===""){
  //       title = "Info"
  //     }
  //     this.toastr.info(message,title,ops)
  //   }
  // }
  showNotify(type,message,title="",ops_config={}){
    let ops={}
    if(Object.keys(ops_config).length === 0){
      ops={
        toastComponent:CallCenterToast,
        titleClass:'toast-title '+type,
        // timeOut:3000
        // easeTime:3000
      }
    }else{
      ops = {
        toastComponent:CallCenterToast,
        titleClass:'toast-title '+type,
        ...ops_config
      }
    }
    // console.log(ops)
    if(type=="success"){
      if(title===""){
        title = "Success"
      }
      this.toastr.success(message,title,ops)
    }else if(type=="error"){
      if(title===""){
        title = "Error"
      }
      this.toastr.error(message,title,ops)
    }else if(type=="plivo"){
      if(title===""){
        title = "Alert Notification"
      }
      this.toastr.error(message,title,ops)
    }else if(type=="warning"){
      if(title===""){
        title = "Warning"
      }
      this.toastr.warning(message,title,ops)
    }else if(type=="info"){
      if(title===""){
        title = "Info"
      }
      this.toastr.info(message,title,ops)
    }
  }
  addFormOverlay(is_modal=false){
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('form-open');
    if(is_modal===true){
      body.classList.add('form-open-modal');
    }
  }
  removeFormOverlay(){
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('form-open');
    body.classList.remove('form-open-modal');
  }
  checkValidPhone(phone){
    const phoneNumber = parsePhoneNumberFromString(phone, 'CA')
    if(phoneNumber && phoneNumber.isValid()===true || phoneNumber.nationalNumber.length == 10){
      return phoneNumber.nationalNumber
    }
    return ""
  }
  checkValidPhoneImport=(phone)=>{
    phone = phone.replace(/\D/g, "")
    const phoneNumber = parsePhoneNumberFromString(phone,{
        defaultCountry: 'CA'
    })
    if(phoneNumber && phoneNumber.isValid()===true){
        return phoneNumber.nationalNumber
    }
    return ""
}
  checkValidEmail(email){
    return validator.isEmail(email)
  }

  checkValidCP(cp){
    return validator.isPostalCode(cp,"CA")
  }

  checkValidDate(date){
    return validator.isDate(date,{format:'YYYY-MM-DD'})
  }

  //Modifies a phone for front-end usage
  parsePhoneNumber(phone){
    const phone_parts = phone.split(';')
    let phone_code =  phone_parts[0]
    let phone_number = phone_parts[1]
    let phone_ext = phone_parts[2] || ''

    try{
      const phoneNumber = parsePhoneNumber((phone_code + phone_number).replace(/;/g, ""))
      phone_ext ? phoneNumber.ext = phone_ext : ''
      return (phone_code || '') + ' ' + phoneNumber.formatNational()
    } catch(e){
      return phone
    }
    
  }

  crmDownload(file){
    this.ApiCrmFileService.get(file.url, file.name).subscribe((res) => {
      if(res.status == "OK"){
        console.log(res)
        const bytes = new Uint8Array(res.data.data)
        const url = window.URL.createObjectURL(new Blob([bytes]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        link.click();
      } else{
        this.hideLoading();
        this.showNotify('error', this.translate.instant('This file has been deleted'))
        console.log(res)
      }
    })
  }

  isEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
  detectContactColumn(row){
    let index =0
    let column={}
    for(const col of row){
      switch (col.toLowerCase()) {
        case 'gender':
        case 'genre':
        case 'sexe':
          column['col_'+index]='gender'
          break;
        case 'name':
        case 'lastname':
        case 'last name':
        case 'last_name':
        case 'nom':
        case 'nom de famille':
          column['col_'+index]='last_name'
          break;
        case 'firstname':
        case 'first_name':
        case 'first name':
        case 'prénom':
        case 'prenom':
          column['col_'+index]='first_name'
          break;
        case 'address':
        case 'adresse 1':
        case 'address 1':
        case 'adresse':
          column['col_'+index]='address1'
          break;
        case 'postalcode':
        case 'zip':
        case 'codepostal':
        case 'code postal':
          column['col_'+index]='postal_code'
          break;

        case 'city':
        case 'ville':
        case 'municipalité':
        case 'municipalite':
        case 'commune':
          column['col_'+index]='city'
          break;
        case 'state':
        case 'province':
        case 'état':
        case 'etat':
          column['col_'+index]='state'
          break;
        case 'phone':
        case 'tel':
        case 'tél':
        case 'telephone':
        case 'téléphone':
        case 'phone1':
        case 'landline':
        case 'phone_number':
          column['col_'+index]='phone_number'
          break;

        case 'cell':
        case 'mobile':
        case 'téléphone mobile':
        case 'telephone mobile':
        case 'cellphone':
          column['col_'+index]='cell_phone'
          break;
        case 'email':
        case 'courriel':
          column['col_'+index]='email'
          break;
        case 'date of birth':
        case 'date_of_birth':
        case 'Naissance':
        case 'Anniversaire':
        case 'date de naissance':
        case 'DNS':
        case 'naissance':
          column['col_'+index]='date_of_birth'
          break;

        case 'tag':
        case 'tags':
        case 'étiquettes':
        case 'étiquette':
          column['col_'+index]='tag'
          break;
      }
      index++
    }
    return column
  }

  detectSmsContactColumn(row){
    let index =0
    let column={}
    for(const col of row){
      switch (col.toLowerCase()) {
        case 'gender':
        case 'genre':
        case 'sexe':
          column['col_'+index]='gender'
          break;
        case 'name':
        case 'lastname':
        case 'last name':
        case 'last_name':
        case 'nom':
        case 'nom de famille':
          column['col_'+index]='last_name'
          break;
        case 'firstname':
        case 'first_name':
        case 'first name':
        case 'prénom':
        case 'prenom':
          column['col_'+index]='first_name'
          break;
        case 'address':
        case 'adresse 1':
        case 'address 1':
        case 'adresse':
          column['col_'+index]='address1'
          break;
        case 'postalcode':
        case 'zip':
        case 'codepostal':
        case 'code postal':
          column['col_'+index]='postal_code'
          break;

        case 'city':
        case 'ville':
        case 'municipalité':
        case 'municipalite':
        case 'commune':
          column['col_'+index]='city'
          break;
        case 'state':
        case 'province':
        case 'état':
        case 'etat':
          column['col_'+index]='state'
          break;
        case 'phone':
        case 'tel':
        case 'tél':
        case 'telephone':
        case 'téléphone':
        case 'phone1':
        case 'landline':
        case 'phone_number':
        case 'cell':
        case 'mobile':
        case 'téléphone mobile':
        case 'telephone mobile':
        case 'cellphone':
          column['col_'+index]='cellphone'
          break;
        case 'email':
        case 'courriel':
          column['col_'+index]='email'
          break;
        case 'date of birth':
        case 'date_of_birth':
        case 'Naissance':
        case 'Anniversaire':
        case 'date de naissance':
        case 'DNS':
        case 'naissance':
          column['col_'+index]='date_of_birth'
          break;

        case 'tag':
        case 'tags':
        case 'étiquettes':
        case 'étiquette':
          column['col_'+index]='tag'
          break;
      }
      index++
    }
    return column
  }
  detectCrmContactColumn(row){
    let index =0
    let column={}
    for(const col of row){
      switch (col.toLowerCase()) {
        case 'gender':
        case 'genre':
        case 'sexe':
          column['col_'+index]='gender'
          break;
        case 'name':
        case 'lastname':
        case 'last name':
        case 'last_name':
        case 'nom':
        case 'nom de famille':
          column['col_'+index]='last_name'
          break;
        case 'firstname':
        case 'first_name':
        case 'first name':
        case 'prénom':
        case 'prenom':
          column['col_'+index]='first_name'
          break;
        case 'civique':
        case 'civic':
          column['col_'+index]='civic'
          break;
        case 'postalcode':
        case 'zip':
        case 'codepostal':
        case 'code postal':
          column['col_'+index]='zip_code'
          break;

        case 'city':
        case 'ville':
        case 'municipalité':
        case 'municipalite':
        case 'commune':
          column['col_'+index]='city'
          break;
        case 'state':
        case 'province':
        case 'état':
        case 'etat':
          column['col_'+index]='state'
          break;
        case 'phone':
        case 'tel':
        case 'tél':
        case 'telephone':
        case 'téléphone':
        case 'phone1':
        case 'landline':
        case 'phone_number':
          column['col_'+index]='phone'
          break;

        case 'cell':
        case 'mobile':
        case 'téléphone mobile':
        case 'telephone mobile':
        case 'cellphone':
          column['col_'+index]='cellphone'
          break;
        case 'email':
        case 'courriel':
          column['col_'+index]='email'
          break;
        case 'date of birth':
        case 'date_of_birth':
        case 'Naissance':
        case 'Anniversaire':
        case 'date de naissance':
        case 'DNS':
        case 'naissance':
          column['col_'+index]='date_of_birth'
          break;

        case 'tag':
        case 'tags':
        case 'étiquettes':
        case 'étiquette':
          column['col_'+index]='tag'
          break;
      }
      index++
    }
    return column
  }
  detectCrmOrganizationColumn(row){
    let index =0
    let column={}
    for(const col of row){
      switch (col.toLowerCase()) {
        case 'name':
          column['col_'+index]='name'
          break;
        case 'civique':
        case 'civic':
          column['col_'+index]='civic'
          break;
        case 'postalcode':
        case 'zip':
        case 'codepostal':
        case 'code postal':
          column['col_'+index]='zip_code'
          break;

        case 'city':
        case 'ville':
        case 'municipalité':
        case 'municipalite':
        case 'commune':
          column['col_'+index]='city'
          break;
        case 'state':
        case 'province':
        case 'état':
        case 'etat':
          column['col_'+index]='state'
          break;
        case 'phone':
        case 'tel':
        case 'tél':
        case 'telephone':
        case 'téléphone':
        case 'phone1':
        case 'landline':
        case 'phone_number':
          column['col_'+index]='phone'
          break;
        case 'email':
        case 'courriel':
          column['col_'+index]='email'
          break;
        case 'date of birth':
        case 'date_of_birth':
        case 'Naissance':
        case 'Anniversaire':
        case 'date de naissance':
        case 'DNS':
        case 'naissance':
          column['col_'+index]='date_of_birth'
          break;

        case 'tag':
        case 'tags':
        case 'étiquettes':
        case 'étiquette':
          column['col_'+index]='tag'
          break;
      }
      index++
    }
    return column
  }
  removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }
  getCustomVariable(){
    return [
      {
        id:'first_name',
        text:this.translate.instant("First name")
      },
      {
        id:'last_name',
        text:this.translate.instant("Last name")
      },
      {
        id:'date_of_birth',
        text:this.translate.instant("Date of birth")
      },
      {
        id:'gender',
        text:this.translate.instant("Gender")
      },
      {
        id:'title',
        text:this.translate.instant("Title")
      },
      {
        id:'address1',
        text:this.translate.instant("Address1")
      },
      {
        id:'country',
        text:this.translate.instant("Country")
      },
      {
        id:'city',
        text:this.translate.instant("City")
      },
      {
        id:'state',
        text:this.translate.instant("State")
      },
      {
        id:'postal_code',
        text:this.translate.instant("Postal code")
      },
      {
        id:'phone_number',
        text:this.translate.instant("Phone number")
      },
      {
        id:'alt_phone',
        text:this.translate.instant("Alt phone")
      },
      {
        id:'cell_phone',
        text:this.translate.instant("Cell phone")
      },
      {
        id:'email',
        text:this.translate.instant("Email")
      }
    ]
  }

  getCrmVariable(){
    return [
      {
        id:'first_name',
        text:this.translate.instant("First name")
      },
      {
        id:'last_name',
        text:this.translate.instant("Last name")
      },
      {
        id:'date_of_birth',
        text:this.translate.instant("Date of birth")
      },
      {
        id:'gender',
        text:this.translate.instant("Gender")
      },
      {
        id:'title',
        text:this.translate.instant("Title")
      },
      {
        id:'zip_code',
        text:this.translate.instant("Postal code")
      },
      {
        id:'phone',
        text:this.translate.instant("Phone number")
      },
      {
        id:'cellphone',
        text:this.translate.instant("Cell phone")
      },
      {
        id:'email',
        text:this.translate.instant("Email")
      },
      {
        id:'address',
        text:this.translate.instant("Address")
      },
      {
        id:'civic',
        text:this.translate.instant("Civic")
      },
      {
        id:'parcel',
        text:this.translate.instant("Parcel")
      },
      {
        id:'street',
        text:this.translate.instant("Street")
      },
      {
        id:'apartment',
        text:this.translate.instant("Apartment")
      },
      {
        id:'country',
        text:this.translate.instant("Country")
      },
      {
        id:'city',
        text:this.translate.instant("City")
      },
      {
        id:'state',
        text:this.translate.instant("State")
      }
    ]
  }
  getCustomVariableUser(){
    return [
      {
        id:'agent_first_name',
        text:this.translate.instant("First name")
      },
      {
        id:'agent_last_name',
        text:this.translate.instant("Last name")
      },
      {
        id:'agent_email',
        text:this.translate.instant("Email")
      }
    ]
  }

  previewImage(url: string, fileName: string) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.target = "_blank";
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  }

  dateObjectToMysqlDate(obj){
    let year = obj.year
    let month = (obj.month<10)?`0${obj.month}`:obj.month
    let day = (obj.day<10)?`0${obj.day}`:obj.day
    return `${year}-${month}-${day}`
  }

  mysqlDateToDateObject(obj){
    let date=obj.split("-")
    return {
      year:parseInt(date[0]),
      month:parseInt(date[1]),
      day:parseInt(date[2])
    }
  }

  replaceAll(str, find, replace) {
    let escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  replaceDialScript(script,contact){
    let customs = this.getCustomVariable()
    for(let custom of customs){
      let field = custom.id
      script = this.replaceAll(script,`[${field}]`,contact[field])
    }
    return script
  }

  getFixedStatusCode(){
    return ["NA","AA","AM","DROP","DNC","ADC","A","ER","BU","POINTED","VOTED","Cancelled"]
  }

  getContactImportColumn(){
    return [
      {
        label:this.translate.instant("First name"),
        description:this.translate.instant("First name"),
        name:'first_name'
      },
      {
        label:this.translate.instant("Last name"),
        description:this.translate.instant("Last name"),
        name:'last_name'
      },
      {
        label:this.translate.instant("Date of birth"),
        description:this.translate.instant("Date of birth"),
        name:'date_of_birth'
      },
      {
        label:this.translate.instant("Gender"),
        description:this.translate.instant("Gender"),
        name:'gender'
      },
      {
        label:this.translate.instant("Address")+" 1",
        description:this.translate.instant("Address")+" 1",
        name:'address1'
      },
      {
        label:this.translate.instant("Address")+" 2",
        description:this.translate.instant("Address")+" 2",
        name:'address2'
      },
      {
        label:this.translate.instant("Address")+" 3",
        description:this.translate.instant("Address")+" 3",
        name:'address3'
      },
      {
        label:this.translate.instant("City"),
        description:this.translate.instant("City"),
        name:'city'
      },
      {
        label:this.translate.instant("State"),
        description:this.translate.instant("State"),
        name:'state'
      },
      {
        label:this.translate.instant("Province"),
        description:this.translate.instant("Province"),
        name:'province'
      },
      {
        label:this.translate.instant("Postal code"),
        description:this.translate.instant("Postal code"),
        name:'postal_code'
      },
      {
        label:this.translate.instant("Phone number"),
        description:this.translate.instant("Phone number"),
        name:'phone_number'
      },
      {
        label:this.translate.instant("Alt phone"),
        description:this.translate.instant("Alt phone"),
        name:'alt_phone'
      },
      {
        label:this.translate.instant("Cell phone"),
        description:this.translate.instant("Cell phone"),
        name:'cell_phone'
      },
      {
        label:this.translate.instant("Email"),
        description:this.translate.instant("Email"),
        name:'email'
      },
      {
        label:this.translate.instant("Comment"),
        description:this.translate.instant("Comment"),
        name:'comment'
      },
      {
        label:this.translate.instant("Tag"),
        description:this.translate.instant("Tag"),
        name:'tag'
      }
    ]
  }
  getSmsContactImportColumn(){
    return [
      {
        label:this.translate.instant("First name"),
        description:this.translate.instant("First name"),
        name:'first_name'
      },
      {
        label:this.translate.instant("Last name"),
        description:this.translate.instant("Last name"),
        name:'last_name'
      },
      {
        label:this.translate.instant("Date of birth"),
        description:this.translate.instant("Date of birth"),
        name:'date_of_birth'
      },
      {
        label:this.translate.instant("Gender"),
        description:this.translate.instant("Gender"),
        name:'gender'
      },
      {
        label:this.translate.instant("Address")+" 1",
        description:this.translate.instant("Address")+" 1",
        name:'address1'
      },
      {
        label:this.translate.instant("Address")+" 2",
        description:this.translate.instant("Address")+" 2",
        name:'address2'
      },
      {
        label:this.translate.instant("Address")+" 3",
        description:this.translate.instant("Address")+" 3",
        name:'address3'
      },
      {
        label:this.translate.instant("City"),
        description:this.translate.instant("City"),
        name:'city'
      },
      {
        label:this.translate.instant("State"),
        description:this.translate.instant("State"),
        name:'state'
      },
      {
        label:this.translate.instant("Province"),
        description:this.translate.instant("Province"),
        name:'province'
      },
      {
        label:this.translate.instant("Postal code"),
        description:this.translate.instant("Postal code"),
        name:'postal_code'
      },
      {
        label:this.translate.instant("Phone number"),
        description:this.translate.instant("Phone number"),
        name:'cellphone'
      },
      {
        label:this.translate.instant("Alt phone"),
        description:this.translate.instant("Alt phone"),
        name:'alt_phone'
      },
      {
        label:this.translate.instant("Email"),
        description:this.translate.instant("Email"),
        name:'email'
      },
      {
        label:this.translate.instant("Comment"),
        description:this.translate.instant("Comment"),
        name:'comment'
      },
      {
        label:this.translate.instant("Tag"),
        description:this.translate.instant("Tag"),
        name:'tag'
      }
    ]
  }

  getCrmContactImportColumn(object=false):any{

    const columns = [
      {
        label:this.translate.instant("First name"),
        description:this.translate.instant("First name"),
        name:'first_name'
      },
      {
        label:this.translate.instant("Last name"),
        description:this.translate.instant("Last name"),
        name:'last_name'
      },
      {
        label:this.translate.instant("Title"),
        description:this.translate.instant("Title"),
        name:'title'
      },
      {
        label:this.translate.instant("Date of birth"),
        description:this.translate.instant("Date of birth"),
        name:'date_of_birth'
      },
      {
        label:this.translate.instant("Gender"),
        description:this.translate.instant("Gender"),
        name:'gender'
      },
      {
        label:this.translate.instant("Language"),
        description:this.translate.instant("Language"),
        name:'language'
      },
      {
        label:this.translate.instant("Civic"),
        description:this.translate.instant("Civic"),
        name:'civic'
      },
      {
        label:this.translate.instant("Parcel"),
        description:this.translate.instant("Parcel"),
        name:'parcel'
      },
      {
        label:this.translate.instant("Street"),
        description:this.translate.instant("Street"),
        name:'street'
      },
      {
        label:this.translate.instant("Apartment"),
        description:this.translate.instant("Apartment"),
        name:'apartment'
      },
      {
        label:this.translate.instant("city"),
        description:this.translate.instant("city"),
        name:'city'
      },
      {
        label:this.translate.instant("State"),
        description:this.translate.instant("State"),
        name:'state'
      },
      {
        label:this.translate.instant("Postal code"),
        description:this.translate.instant("Postal code"),
        name:'zip_code'
      },
      {
        label:this.translate.instant("Phone number"),
        description:this.translate.instant("Phone number"),
        name:'phone'
      },
      {
        label:this.translate.instant("Cell phone"),
        description:this.translate.instant("Cell phone"),
        name:'cellphone'
      },
      {
        label:this.translate.instant("Email"),
        description:this.translate.instant("Email"),
        name:'email'
      },
      {
        label:this.translate.instant("Tag"),
        description:this.translate.instant("Tag"),
        name:'tag'
      }
    ]
    if(object===false){
      return columns
    }else{
      const col_object = {}
      for(const col of columns){
        col_object[col.name]=col.label
      }
      return col_object
    }
  }
  getCrmOrganizationImportColumn(object=false):any{

    const columns = [
      {
        label:this.translate.instant("Name"),
        description:this.translate.instant("Name"),
        name:'name'
      },
      {
        label:this.translate.instant("Industry"),
        description:this.translate.instant("Industry"),
        name:'industry'
      },
      {
        label:this.translate.instant("Website"),
        description:this.translate.instant("Website"),
        name:'website'
      },
      {
        label:this.translate.instant("Language"),
        description:this.translate.instant("Language"),
        name:'language'
      },
      {
        label:this.translate.instant("Civic"),
        description:this.translate.instant("Civic"),
        name:'civic'
      },
      {
        label:this.translate.instant("Parcel"),
        description:this.translate.instant("Parcel"),
        name:'parcel'
      },
      {
        label:this.translate.instant("Street"),
        description:this.translate.instant("Street"),
        name:'street'
      },
      {
        label:this.translate.instant("Apartment"),
        description:this.translate.instant("Apartment"),
        name:'apartment'
      },
      {
        label:this.translate.instant("city"),
        description:this.translate.instant("city"),
        name:'city'
      },
      {
        label:this.translate.instant("State"),
        description:this.translate.instant("State"),
        name:'state'
      },
      {
        label:this.translate.instant("Postal code"),
        description:this.translate.instant("Postal code"),
        name:'zip_code'
      },
      {
        label:this.translate.instant("Phone number"),
        description:this.translate.instant("Phone number"),
        name:'phone'
      },
      {
        label:this.translate.instant("Email"),
        description:this.translate.instant("Email"),
        name:'email'
      },
      {
        label:this.translate.instant("Tag"),
        description:this.translate.instant("Tag"),
        name:'tag'
      }
    ]
    if(object===false){
      return columns
    }else{
      const col_object = {}
      for(const col of columns){
        col_object[col.name]=col.label
      }
      return col_object
    }
  }
  showLoading(){
    console.log("In SHOW")
    let loading = document.querySelector('.loading-container')
    if(loading){
      (document.querySelector('.loading-container') as HTMLElement).style.display = 'block';
    }
  }
  hideLoading(){
    console.log("In HIDE")
    const loading = document.querySelector('.loading-container')
    if(loading){
      (document.querySelector('.loading-container') as HTMLElement).style.display = 'none';
    }
  }

  hexToRgb(hex){
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  applyOrganizationColor(){
    const logged = this.sessionStorageService.get('logged')

    if(logged.organization.color_primary=="" || logged.organization.color_primary==null){
      logged.organization.color_primary="#5E81F4"
    }

    if(logged.organization.color_secondary=="" || logged.organization.color_secondary==null){
      logged.organization.color_secondary="#81F45E"
    }

    if(logged.organization.color_tertiary=="" || logged.organization.color_tertiary==null){
      logged.organization.color_tertiary="#D15EF4"
    }

    const rgb_pri= this.hexToRgb(logged.organization.color_primary)
    const rgb_sec = this.hexToRgb(logged.organization.color_secondary)
    const rgb_ter= this.hexToRgb(logged.organization.color_tertiary)
    const colors = new Map([
      ['primary', logged.organization.color_primary],
      ['primary_opacity', `rgba(${rgb_pri.r},${rgb_pri.g},${rgb_pri.b},0.1)`],
      ['primary_rgb', `${rgb_pri.r},${rgb_pri.g},${rgb_pri.b}`],
      ['secondary', logged.organization.color_secondary],
      ['secondary_opacity', `rgba(${rgb_sec.r},${rgb_sec.g},${rgb_sec.b},0.1)`],
      ['tertiary', logged.organization.color_tertiary],
      ['tertiary_opacity', `rgba(${rgb_ter.r},${rgb_ter.g},${rgb_ter.b},0.1)`],
    ])
    
    Array.from(colors.entries()).forEach(([name, value]) => {
      document.body.style.setProperty(`--${name}`, value);
    })
  }
  getCurrentTimestamp(){
    return Math.round(new Date().getTime()/1000)    
  }
  getCampaignAction(){
    return [
      // {
      //   id:'send_email',
      //   name:this.translate.instant("Send email"),
      //   description:this.translate.instant("Send a predetermined email")
      // },
      // {
      //   id:'send_sms',
      //   name:this.translate.instant("Send sms"),
      //   description:this.translate.instant("Send a predetermined sms")
      // },
      {
        id:'open_an_url',
        name:this.translate.instant("Open an URL"),
        description:this.translate.instant("Open an URL")
      }
    ]
  }


  inputFeedBack(status, parent, placement="left"){
    //Get input element to disable it during the animation
    const input = parent.getElementsByTagName('input')[0] || parent.getElementsByTagName('ng-select')[0] || parent.getElementsByTagName('textarea')[0] || parent.getElementsByTagName('select')[0] || parent.getElementsByTagName('div')[0]
    const container = document.createElement('div')
    parent.style.position = 'relative'
    const containerHeight = parent.offsetHeight;
    const top = containerHeight / 2
    let fontSize = '24px';
    let paddingDir
    let offset
    if(parent.classList.contains('small'))
      fontSize = '20px';
    if(placement == 'left'){
      paddingDir = window.getComputedStyle( parent , null).getPropertyValue("padding-left")
      offset = parseInt(paddingDir.split('px')[0]) / 2 - parseInt(fontSize.split('px')[0]) / 2; 
    } else if(placement == 'right'){
      paddingDir = window.getComputedStyle( parent , null).getPropertyValue("padding-right")
      offset = parent.clientWidth - parseInt(window.getComputedStyle( parent , null).getPropertyValue("padding-left").split('px')[0]) - parseInt(paddingDir.split('px')[0]) / 2 - parseInt(fontSize.split('px')[0]) / 2; 
    }
  
    //Programmatically determine the position of the feedback icon based on the container and the icon size
    container.style.position = 'absolute'
    container.style.left = offset + 'px'
    container.style.top = top - parseInt(fontSize.split('px')[0]) / 2 + 'px'
    input.style.pointerEvents = 'none'
    const icon = document.createElement('i')
    icon.style.position = 'absolute'
   
    //Make the icon appear and change the background color of the parent container
    if(status == 'success'){
      parent.classList.add('success')
      icon.style.color = 'rgb(94, 129, 244)'
      icon.classList.add(...['fas', 'fa-check', 'feedback-animation'])      
    }
    else if(status == 'error'){
      parent.classList.add('error')
      icon.style.color = 'rgb(255, 128, 139)'
      icon.classList.add(...['fas', 'fa-exclamation-circle', 'feedback-animation']) 
    }
    parent.insertBefore(container, parent.firstChild);
    //Remove all styles and the icon after the animation is finished
    container.appendChild(icon)
      setTimeout(() => {
        icon.style.fontSize = fontSize
      }, 50)
      setTimeout(() => {
        parent.classList.remove('success')
        parent.classList.remove('error')
        icon.style.fontSize = '0px'
        input.style.pointerEvents = 'all'
        setTimeout(() => {
          container.remove()
        }, 1000)
      }, 2000)
  }

  showFieldError(elem, message){
    this.showNotify('error', this.translate.instant(message))
    elem.classList.add('notify-error')
      setTimeout(() => {
        elem.classList.remove('notify-error')
      }, 2000)
  }

  playAudio(type){
    let ref = ""
    switch(type){
      case 'menu':
        ref = '../../../assets/audio/Menu.mp3'
        break;
      case 'confirm':
        ref = '../../../assets/audio/Confirm.mp3'
        break;
      case 'submit':
        ref = '../../../assets/audio/Submit.mp3'
        break;
      case 'error':
        ref = '../../../assets/audio/Error.mp3'
        break;
      case "notify":
        ref = '../../../assets/audio/Notify.wav'
        break
    }
    if(ref){
      const audio = new Audio();
      audio.src = ref;
      audio.load();
      audio.play();
    }
  }

  addPhoneMask(phone){
    phone = phone.replace(/\D/g, '');
    if (phone.length == 0) {
      phone = '';
    } 
    // don't show braces for empty groups at the end
    else if (phone.length <= 3) {
      phone = phone.replace(/^(\d{0,3})/, '($1)');
    } else if (phone.length <= 6) {
      phone = phone.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (phone.length <= 10) {
      phone = phone.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
    } 
    return phone
  }

  getRawHTMLFromRTF(emailData){
    const rtf = emailData.compressedRtf
    return new Promise((resolve, reject) => {
      const outputArray = decompressRTF(Array.from(rtf))

      const rtfStr = Buffer.from(outputArray).toString("utf-8");
      // console.log(rtfStr)
      try {
          
        rtfToHTML.fromString(rtfStr, (err, html) => {
          if(html){
            resolve(html)
          }else{
            resolve(emailData.body.replace(/\n/g, "<br />"))
          }
        });
      } catch (error) {
        resolve(emailData.body.replace(/\n/g, "<br />"))
      }
    })
    
  }


  async parseEmailFile(file){
    return new Promise(async (resolve, reject) => {
      if(file.name.includes('.msg') || (file.name == 'msg' && !file.name.includes('.'))){
        const buffer = await file.arrayBuffer()
        var reader = new MsgReader(buffer) as any;
        let emailData = reader.getFileData();
       
        let files = []
        if(emailData.attachments)
          files = emailData.attachments.filter((file) => file.extension).map((file, i) => {return{...file, mimeType: mime.types[file.extension.split('.')[file.extension.split('.').length - 1]], content: reader.getAttachment(i).content}});

        // console.log(emailData)
        const html = await this.getRawHTMLFromRTF(emailData)
        console.log('html ',html)
        const result = {
          attachments: files.map((file) => {
            return {
              content: file.content.buffer,
              filename: file.fileName,
              mimeType: file.mimeType
            }
          }),
          date: moment(emailData.messageDeliveryTime).format(),
          deliveredTo: emailData.lastModifierSMTPAddress,
          from: {
            address: emailData.senderEmail,
            name: emailData.senderName
          },
          html: html,
          subject: emailData.subject,
          text: emailData.body,
          to: emailData.recipients.filter((recip) => recip.recipType == 'to').map((recip) => {return{address: recip.smtpAddress, name: recip.name}}),
          cc: emailData.recipients.filter((recip) => recip.recipType == 'cc').map((recip) => {return{address: recip.smtpAddress, name: recip.name}}),
          cci: emailData.recipients.filter((recip) => recip.recipType == 'bcc').map((recip) => {return{address: recip.smtpAddress, name: recip.name}})
        }
        resolve(result)
      } else if(file.name.includes('.eml') || (file.name == 'eml' && !file.name.includes('.'))){
        const parser = new postalMime.default();
        let fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onloadend = (x) => {
          parser.parse(fileReader.result).then((res) => {
            resolve(res)
          });
        }
      }
    })
    
  }

  async detectFileEncoding(file):Promise<string>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader();
      reader.readAsText(file, "utf-8");
      reader.onload = function (evt) {
          if(evt.target.result.toString().includes("�") || evt.target.result.toString().includes("Ã")|| evt.target.result.toString().includes("©")){
            resolve("iso-8859-3")
          }else{
            resolve("utf-8")
          }
      }
    })
  }
  generateRandomColor(){
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  getAveryLabelSelect(){
    return [
      {
        id: '5160',
        title: this.translate.instant('Avery 5160'),
        config:{
          "paper-size":"letter",
          "metric":"mm",
          "marginLeft":1.762,
          "marginTop":10.7,
          "NX":3,
          "NY":10,
          "SpaceX":3.175,
          "SpaceY":0,
          "width":66.675,
          "height":25.4,
          "font-size":8
        }
      },{
        id: '5161',
        title: this.translate.instant('Avery 5161'),
        config:{
          "paper-size": "letter",
          "metric": "mm",
          "marginLeft": 0.967,
          "marginTop": 10.7,
          "NX": 2,
          "NY": 10,
          "SpaceX": 3.967,
          "SpaceY": 0,
          "width": 101.6,
          "height": 25.4,
          "font-size": 8
        }
      },{
        id: '5162',
        title: this.translate.instant('Avery 5162'),
        config:{
          "paper-size": "letter",
          "metric": "mm",
          "marginLeft": 10.97,
          "marginTop": 20.224,
          "NX": 2,
          "NY": 7,
          "SpaceX": 4,
          "SpaceY": 1.333,
          "width": 107,
          "height": 34,
          "font-size": 10.5
        }
      },{
        id: '5163',
        title: this.translate.instant('Avery 5163'),
        config:{
          "paper-size": "letter",
          "metric": "mm",
          "marginLeft": 1.762,
          "marginTop": 10.7,
          "NX": 2,
          "NY": 5,
          "SpaceX": 3.175,
          "SpaceY": 0,
          "width": 101.6,
          "height": 50.8,
          "font-size": 8
        }
      },{
        id: '5164',
        title: this.translate.instant('Avery 5164'),
        config:{
          "paper-size": "letter",
          "metric": "in",
          "marginLeft": 0.148,
          "marginTop": 0.5,
          "NX": 2,
          "NY": 3,
          "SpaceX": 0.2031,
          "SpaceY": 0,
          "width": 4,
          "height": 3.33,
          "font-size": 12
        }
      }
      // ,{
      //   id: '5165',
      //   title: this.translate.instant('Avery 5165'),
      //   config:{
      //     "paper-size": "letter",
      //     "metric": "mm",
      //     "marginLeft": 10.97,
      //     "marginTop": 20.224,
      //     "NX": 2,
      //     "NY": 7,
      //     "SpaceX": 4,
      //     "SpaceY": 1.333,
      //     "width": 107,
      //     "height": 34,
      //     "font-size": 9.5
      //   }
      // },{
      //   id: '8600',
      //   title: this.translate.instant('Avery 8600'),
      //   config:{
      //     "paper-size": "letter",
      //     "metric": "mm",
      //     "marginLeft": 7.1,
      //     "marginTop": 19,
      //     "NX": 3,
      //     "NY": 10,
      //     "SpaceX": 9.5,
      //     "SpaceY": 3.1,
      //     "width": 66.6,
      //     "height": 25.4,
      //     "font-size": 8
      //   }
      // }
      
    ]
  }
  getSettingEmails(){
    return [
      {
        id:0,
        name:this.translate.instant("Unknown")
      },{
        id:1,
        name:this.translate.instant("OK")
      },{
        id:2,
        name:this.translate.instant("Unsubscribe")
      },{
        id:3,
        name:this.translate.instant("Personnel seulement")
      }

    ]
  }
  getEmailStatus(){
    return [
      {
        id:0,
        name:this.translate.instant("Unknown")
      },{
        id:1,
        name:this.translate.instant("Valid")
      },{
        id:2,
        name:this.translate.instant("Not valid")
      },{
        id:3,
        name:this.translate.instant("Hard bounce")
      },{
        id:4,
        name:this.translate.instant("Soft bounce")
      }

    ]
  }
  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
