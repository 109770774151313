import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";
import {UtilsService} from "../utils.service";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiCrmFileService {
    api_name = "crm/file";
    url = "3.96.35.194";
    constructor( 
      private httpClient: HttpClient,
      private utils:UtilsService
    ) {}
    
    get(url, name): Observable<any> {
      return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}?url=${url}&name=${name}`, this.utils.getAuthHeader())
    }

    addFile(data: any): Observable<any>{
      return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`, data, this.utils.getAuthHeader())
    }

    deleteFile(id: number): Observable<any>{
        return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/:${id}`, this.utils.getAuthHeader())
    }
  }