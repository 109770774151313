import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable({
  providedIn: 'root'
})
export class ApiFileService {
  api_name = "file"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  getRecord(call_uuid):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/record?call_uuid=${call_uuid}`, this.utils.getAuthHeader())
  }
}