export const environment = {
  production: true,
  api_url:'https://callapi3.democratik.org',
  democratik_api_url:'https://monelection.org',
  secret_key:'predictive',
	AwsUserPoolId: 'ca-central-1_JQzC11Fsx', 
  AwsIdentifyPoolId:'ca-central-1:35af282b-8f49-43e9-8814-4f0c57beb6d3',
	AwsClientId: '3fj1h70p6cregcf0rdm2p87745', 
  AwsRegion:'ca-central-1'
};
