import { SuperAdminModule } from './super-admin/super-admin.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";

import { AuthGuard } from '../core/services/auth-guard.service';
import {PublicComponent} from "../shared/layouts/public/public.component";
import {AdminComponent} from "../shared/layouts/admin/admin.component";
import {DocumentComponent} from "./public/document/document.component";
import {UserComponent} from "../shared/layouts/user/user.component";
import { AuthGuardAdmin } from '../core/services/auth-guard.admin.service';
import { SuperAdminComponent } from '../shared/layouts/super-admin/super-admin.component';
import { AuthGuardSuperAdmin } from '../core/services/auth-guard.super-admin.service';



const routes: Routes = [
  {
    path: 'super-admin',
    component:SuperAdminComponent,
    canActivate:[AuthGuardSuperAdmin],
    loadChildren: () => import('./super-admin/super-admin.module').then(mod => mod.SuperAdminModule)
  },
  {
    path: 'admin',
    component:AdminComponent,
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'user',
    component:UserComponent,
    loadChildren: () => import('./user/user.module').then(mod => mod.UserModule)
  },
  {
    path: 'signin',
    component:PublicComponent,
    loadChildren: () => import('./public/public.module').then(mod => mod.PublicModule)
  },
  {
    path: 'document',
    component:DocumentComponent
  },
  {
    path: 'quickbook',
    component:DocumentComponent
  },
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'signin',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [CommonModule,RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard,AuthGuardSuperAdmin]
})
export class FeatureRoutingModule {}
