import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiDialService {
  api_name = "dial"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }

  checkCampaign(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/check-campaign/${campaign_id}`, this.utils.getAuthHeader())
  }
  initConference(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/conference/${campaign_id}`, this.utils.getAuthHeader())
  }

  activeConference(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/conference-start/${campaign_id}`, this.utils.getAuthHeader())
  }

  pauseConference(campaign_id,reason):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/conference-end/${campaign_id}`,{reason}, this.utils.getAuthHeader())
  }

  leaveConference():Observable<any> {
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/conference`, this.utils.getAuthHeader())
  }
  updateHooper(campaign_id,status=""):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/hooper/${campaign_id}`,{status}, this.utils.getAuthHeader())
  }
  updateStatus(campaign_id,obj):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/status/${campaign_id}`,obj, this.utils.getAuthHeader())
  }

  updateConferenceStatus(campaign_id,obj):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/conference-status/${campaign_id}`,obj, this.utils.getAuthHeader())
  }

  checkHangup(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/check-hangup/${campaign_id}`, this.utils.getAuthHeader())
  }

  getPhone(campaign_id,data):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/phone/${campaign_id}`,data, this.utils.getAuthHeader())
  }

  getDial(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/dial/${campaign_id}`, this.utils.getAuthHeader())
  }
  endDial(campaign_id,obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/end/${campaign_id}`,obj, this.utils.getAuthHeader())
  }

  endDialDispo(campaign_id,obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/end-dispo/${campaign_id}`,obj, this.utils.getAuthHeader())
  }

  saveLog(campaign_id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/log/${campaign_id}`, this.utils.getAuthHeader())
  }
  saveLogAgent(campaign_id,obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/log-agent/${campaign_id}`,obj, this.utils.getAuthHeader())
  }
  getProgression(campaign_id,type='today'):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/progression/${campaign_id}?type=${type}`, this.utils.getAuthHeader())
  }
  removeAgent(campaign_id,agent_id){
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/conference-agent/${campaign_id}/${agent_id}`, this.utils.getAuthHeader())
  }
  recallContact(campaign_id,phone_code,phone_number,list_id){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/recall/${campaign_id}`,{phone_code,phone_number,list_id}, this.utils.getAuthHeader())
  }

  muteMember(campaign_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/muted/${campaign_id}`, this.utils.getAuthHeader())
  }
  unmuteMember(campaign_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/unmuted/${campaign_id}`, this.utils.getAuthHeader())
  }
  listenCallAgent(agent_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/listen/${agent_id}`, this.utils.getAuthHeader())
  }
  quitCallAgent(agent_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/leave-conference/${agent_id}`, this.utils.getAuthHeader())
  }
  sendDigits(campaign_id,digit){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/send-digit/${campaign_id}`,{digit}, this.utils.getAuthHeader())
  }

  filterCallLog(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/call-log-filter`,obj, this.utils.getAuthHeader())
  }
  filterCallLogAgent(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/call-log-filter-agent`,obj, this.utils.getAuthHeader())
  }
  downloadCallLog(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/call-log-download`,obj, this.utils.getAuthHeader())
  }
  downloadCallLogAgent(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/call-log-agent-download`,obj, this.utils.getAuthHeader())
  }
  
  getCallLogOptions(){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/call-log-options/`, this.utils.getAuthHeader())
  }
  updateLastSeen(campaign_id){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/last-seen/${campaign_id}`,{}, this.utils.getAuthHeader())
  }

  updateLastSeenCheck(campaign_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/last-seen-check/${campaign_id}`, this.utils.getAuthHeader())
  }
  flagCall(campaign_id,call_uuid,flag){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/flag/${campaign_id}`,{call_uuid,flag}, this.utils.getAuthHeader())
  }
  sendEmail(obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/send-email`,obj, this.utils.getAuthHeader())
  }

  sendSms(obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/send-sms`,obj, this.utils.getAuthHeader())
  }
  getDialOption(campaign_id=0,is_twilio=0): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/dial-page-option?campaign_id=${campaign_id}&is_twilio=${is_twilio}`, this.utils.getAuthHeader())
  }

  getReport(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/report`,filter, this.utils.getAuthHeader())
  }
  getReportAgent(filter={},agent_id):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/report/agent-expand/${agent_id}`,filter, this.utils.getAuthHeader())
  }
  getCall(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-call`,obj, this.utils.getAuthHeader())
  }
  getDashboard(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-dashboard`,obj, this.utils.getAuthHeader())
  }
  getUserDashboard(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-user-dashboard`,obj, this.utils.getAuthHeader())
  }

  getDashboardTopStat(type):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-dashboard-top-stat`,{type}, this.utils.getAuthHeader())
  }
  getCallCost(type,view,leader_board=0):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-call-cost`,{type,view,leader_board}, this.utils.getAuthHeader())
  }

  getUserDashboardAgentStat(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/get-user-dashboard-agent-stat`,obj, this.utils.getAuthHeader())
  }
  
  runCampaignAction(obj):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/run-action`,obj, this.utils.getAuthHeader())
  }
  sendPlivoNotify(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/plivo-notify`,obj, this.utils.getAuthHeader())
  }
  validateOutboundCall(campaign_id,obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/validate-outbound/${campaign_id}`,obj, this.utils.getAuthHeader())
  }
  reportBug(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/report-bug`,obj, this.utils.getAuthHeader())
  }
  reportBugAuto(obj={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/report-bug-auto`,obj, this.utils.getAuthHeader())
  }
  getConference(campaign_id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/debug-conference/${campaign_id}`, this.utils.getAuthHeader())
  }
  addConference(campaign_id,user_id){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/debug-conference/${campaign_id}`,{user_id}, this.utils.getAuthHeader())
  }
  startConference(campaign_id,user_id){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/debug-conference-start/${campaign_id}`,{user_id}, this.utils.getAuthHeader())
  }
  
  hangupPhoneDial(campaign_id,user_id){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/debug-conference/end-dial/${campaign_id}`,{user_id}, this.utils.getAuthHeader())
  }
  debugHooper(campaign_id,user_id){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/debug-conference/hooper/${campaign_id}`,{user_id}, this.utils.getAuthHeader())
  }

  updateDebugStatus(campaign_id,obj):Observable<any> {
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/debug-conference/status/${campaign_id}`,obj, this.utils.getAuthHeader())
  }

  getMissingPusher(campaign_id,last_pusher_id){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/missing-pusher/${campaign_id}`,{
      last_pusher_id
    }, this.utils.getAuthHeader())
  }

}



