import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiCampaignService {
  api_name = "call_campaign"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  // filter(filter={}):Observable<any> {
  //   return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  // }

  filter(filter = {}): Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`, JSON.stringify(filter), this.utils.getAuthHeader());
  }

  getOptions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/options`, this.utils.getAuthHeader());
  }

  updateOptions(data): Observable<any>{
    return this.httpClient.patch<any>(`${environment.api_url}/api/${this.api_name}/options`, data,this.utils.getAuthHeader());
  }
  get():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}`, this.utils.getAuthHeader())
  }
  dialList():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/dial-list`, this.utils.getAuthHeader())
  }
  getItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  clone(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/clone/${id}`, this.utils.getAuthHeader())
  }
  
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  inactive(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/inactive/${id}`, this.utils.getAuthHeader())
  }
  active(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/active/${id}`, this.utils.getAuthHeader())
  }
  transfer(id,obj){
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/transfer/${id}`,obj, this.utils.getAuthHeader())
  }
  getCampaignPageOption(){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/page-option`, this.utils.getAuthHeader())
  }

  saveRecycle(id,obj){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/recycle/${id}`,obj, this.utils.getAuthHeader())
  }
  getRecycleContact(id,obj){
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/recycle-contact/${id}`,obj, this.utils.getAuthHeader())
  }
  resetHooper(id){
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/reset-hooper/${id}`, this.utils.getAuthHeader())
  }


  getCategories():Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/categories`, this.utils.getAuthHeader())
  }
  addCategory(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/categories`,obj, this.utils.getAuthHeader())
  }
  editCategory(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/categories/${id}`,obj, this.utils.getAuthHeader())
  }

}



