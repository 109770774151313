import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() paging_id:string
  @Input() limit: number = 10
  @Input() options = [
    {id:'10','text':10},
    {id:'30','text':30},
    {id:'60','text':60},
    {id:'120','text':120},
    {id:'240','text':240},
  ]
  @Output() changePage = new EventEmitter<number>();
  @Output() changeLimit = new EventEmitter<string>();

  constructor() { }
  item_per_page=[]
  ngOnInit(): void {
    this.item_per_page= this.options
  }

}
