import { ConfigComponent } from './crm/contact-config/contact-config.component';
import { ViewCampaignFooterComponent } from './../feature/user/campaign-dial-v3/view-campaign-footer/view-campaign-footer.component';
import { MessengerSidebarComponent } from './messenger-sidebar/messenger-sidebar.component';
import { MessengerComponent } from './messenger/messenger.component';
import { DotAnimationComponent } from './dot-animation/dot-animation.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxMaskModule } from 'ngx-mask';
import { IconModule } from '@visurel/iconify-angular';
import { CoreModule } from 'src/app/core/core.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ConfigOrganizationComponent } from './components/config-organization/config-organization.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { UserProfileComponent } from './components/user-profile.component';
import { DashboardCampaignComponent } from './components/dashboard-campaign/dashboard-campaign.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import { PublicComponent } from './layouts/public/public.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { NgDragDropModule } from 'ng-drag-drop';
import {TranslateModule} from "@ngx-translate/core";
import { SubMenuComponent } from './layouts/sub-menu/sub-menu.component';
import {FormsModule} from "@angular/forms";
import { PageHeaderComponent } from './layouts/page-header/page-header.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import { MainMenuComponent } from './layouts/main-menu/main-menu.component';
import { PaginationComponent } from './layouts/pagination/pagination.component';
import {NgxPaginationModule} from "ngx-pagination";
import { TableHeaderComponent } from './layouts/table-header/table-header.component';
import {NgSelect2Module} from "ng-select2";
import {ActionDropdownComponent} from "./layouts/action-dropdown/action-dropdown.component";
import { TimerCounterComponent } from './components/timer-counter/timer-counter.component';
import {NgbDatepickerModule, NgbDropdownModule, NgbTimepickerModule} from "@ng-bootstrap/ng-bootstrap";
import { ContactColumnMappingComponent } from './components/contact-column-mapping/contact-column-mapping.component';
import { ScriptEditorComponent } from './components/script-editor/script-editor.component';
import {QuillModule} from "ngx-quill";
import { UserComponent } from './layouts/user/user.component';
import { UserMenuComponent } from './layouts/user-menu/user-menu.component';
import { QuickSearchComponent } from './layouts/quick-search/quick-search.component';
import { UserSubMenuComponent } from './layouts/user-sub-menu/user-sub-menu.component';
import { TimerComponent } from './components/timer/timer.component';
import { CustomColumnComponent } from './components/custom-column/custom-column.component';
import { BeeEditorComponent } from './components/bee-editor/bee-editor.component';
import { NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgApexchartsModule } from 'ng-apexcharts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DashboardAgentComponent } from './components/dashboard-agent/dashboard-agent.component';
import { AgentViewHeaderComponent } from './agent-view-header/agent-view-header.component';
import { ColorBoxComponent } from './color-box/color-box.component';
import { ScriptUserDropdownComponent } from './script-user-dropdown/script-user-dropdown.component';
import { CampaignViewHeaderComponent } from './campaign-view-header/campaign-view-header.component';
import { LoaderComponent } from './loader/loader.component';
import { ConversationComponent } from './conversation/conversation.component';
import { UserPageHeaderComponent } from './layouts/user-page-header/user-page-header.component';
import { MaterialModule } from '../material/material.module';
import { SearchBarComponent } from './crm/search-bar/search-bar.component';
import { QuickFilterFormComponent } from './crm/quick-filter-form/quick-filter-form.component';
import { FilterHeaderComponent } from './crm/filter-header/filter-header.component';
import { AdvancedFilterComponent } from './crm/advanced-filter/advanced-filter.component';
import { EnumerationOverflowPipe } from '../core/pipe/enumeration-overflow.pipe';
import { AutocompleteComponent } from './crm/autocomplete/autocomplete.component';
import { BoldTextMatchPipe } from '../core/pipe/bold-text-match.pipe';
import { ItemsListComponent } from './crm/items-list/items-list.component';
import { ListItemLoadingComponent } from './crm/list-item-loading/list-item-loading.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { MailFormModalComponent } from './crm/mail-form-modal/mail-form-modal.component';
import { MenuComponent } from './crm/menu/menu.component';
import { OptionsDropDownComponent } from './crm/options-dropdown/options-dropdown.component';
import { ZoomToolComponent } from './components/zoom-tool/zoom-tool.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { CrmPageHeaderComponent } from './crm/page-header/page-header.component';
import { AddRelationComponent } from './crm/add-relation/add-relation.component';
import { GlobalSearchComponent } from './crm/global-search/global-search.component';
import { ShortcutsComponent } from './crm/shortcuts/shortcuts.component';
import { SafeHtmlPipe } from '../core/services/safe-html.pipe';
import { FieldsListSelectorComponent } from './crm/fields-list-selector/fields-list-selector.component';
@NgModule({
    declarations: [PublicComponent, AdminComponent, SubMenuComponent, PageHeaderComponent, MainMenuComponent, PaginationComponent, TableHeaderComponent, ActionDropdownComponent, TimerCounterComponent, DateTimePickerComponent, ContactColumnMappingComponent,
        ScriptEditorComponent,
        UserComponent,
        UserMenuComponent,
        QuickSearchComponent,
        UserSubMenuComponent,
        TimerComponent,
        CustomColumnComponent, CustomColumnComponent, BeeEditorComponent,DashboardCampaignComponent,UserProfileComponent,ConfigOrganizationComponent,DashboardAgentComponent,
    AgentViewHeaderComponent,
    ColorBoxComponent,
    ScriptUserDropdownComponent,
    CampaignViewHeaderComponent,
    LoaderComponent,
    DotAnimationComponent,
    MessengerComponent,
    MessengerSidebarComponent,
    ConversationComponent,
    UserPageHeaderComponent,
    SearchBarComponent,
    QuickFilterFormComponent,
    FilterHeaderComponent,
    AdvancedFilterComponent,
    EnumerationOverflowPipe,
    AutocompleteComponent,
    BoldTextMatchPipe,
    ItemsListComponent,
    ListItemLoadingComponent,
    FileViewerComponent,
    MailFormModalComponent,
    MenuComponent,
    OptionsDropDownComponent,
    ZoomToolComponent,
    SkeletonLoaderComponent,
    CrmPageHeaderComponent,
    AddRelationComponent,
    GlobalSearchComponent,
    ShortcutsComponent,
    SafeHtmlPipe,
    ConfigComponent,
    FieldsListSelectorComponent
  ],
  exports: [
    PageHeaderComponent,
    MainMenuComponent,
    SubMenuComponent,
    PaginationComponent,
    TableHeaderComponent,
    ActionDropdownComponent,
    TimerCounterComponent,
    DateTimePickerComponent,
    ContactColumnMappingComponent,
    ScriptEditorComponent,
    UserMenuComponent,
    UserSubMenuComponent,
    TimerComponent,
    CustomColumnComponent,
    BeeEditorComponent,
    DashboardCampaignComponent,
    DashboardAgentComponent,
    UserProfileComponent,
    ConfigOrganizationComponent,
    AgentViewHeaderComponent,
    ColorBoxComponent,
    ScriptUserDropdownComponent,
    CampaignViewHeaderComponent,
    LoaderComponent,
    DotAnimationComponent,
    MessengerComponent,
    MessengerSidebarComponent,
    ConversationComponent,
    UserPageHeaderComponent,
    SearchBarComponent,
    QuickFilterFormComponent,
    FilterHeaderComponent,
    AdvancedFilterComponent,
    ItemsListComponent,
    ListItemLoadingComponent,
    ConfigComponent,
    FieldsListSelectorComponent,
    OptionsDropDownComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    FormsModule,
    IconModule,
    NgbModule,
    DragDropModule,
    NgDragDropModule,
    TranslateModule,
    NgxPaginationModule,
    NgSelect2Module,
    NgSelectModule,
    MatProgressSpinnerModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    QuillModule,
    NgApexchartsModule,
    ImageCropperModule,
    NgCircleProgressModule,
    ColorPickerModule,
    GooglePlaceModule,
    NgxMaskModule,
    PickerModule,
    MaterialModule
  ]
})
export class SharedModule { }
